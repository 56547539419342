/* You can add global styles to this file, and also import other style files */

nb-icon[size="small"] {
  &::before {
    font-size: 0.8rem;
  }
}
nb-icon[size="medium"] {
  &::before {
    font-size: 1.2rem;
  }
}

nb-icon[size="large"] {
  &::before {
    font-size: 1.5rem;
  }
}
nb-icon[size="giant"] {
  &::before {
    font-size: 2rem;
  }
}

input.ng-invalid,
nb-select.ng-invalid {
  border: 1px solid red;
}
